<template>
  <div>
    <v-btn small @click="withdrawDialog = true">Reembolsar</v-btn>

    <!-- Dialog withdraw -->
    <DialogBox :model="withdrawDialog" :max_width="'800'">
      <template slot="toolbar">
        <span>Reembolsar</span>
      </template>
      <template slot="content">
        <v-select
          v-model="selectedCurrencyToWithdraw"
          :items="getWithdrawableCurrencies()"
          label="Moneda a reembolsar"
          @change="setWithdrawData(selectedCurrencyToWithdraw)"
        />

        <div v-if="selectedCurrencyToWithdraw !== 'ALLWALLETS' && selectedCurrencyToWithdraw !== ''" class="mt-5">
          <div v-if="withdrawType === 'byOffices'">
            <p class="heading4-bold">Selecciona el importe por oficina o reembolsa todo</p>

            <v-data-table :headers="walletsTableHeaders" :items="withdrawData.wallets" hide-default-footer disable-sort class="secondary">
              <template v-slot:[`item.available_funds`]="props">
                {{ currency(props.item.available_funds, { fromCents: true }) }}
              </template>
              <template v-slot:[`item.withholding_withdraws`]="props">
                {{ props.item.withholding_withdraws }}% =
                {{ getAmountWithholding(props.item.amountToWithdraw, props.item.withholding_withdraws) }}
              </template>
              <template v-slot:[`item.amountToWithdraw`]="props">
                <v-form ref="withdrawForm" v-model="withdrawFormValid">
                  <v-text-field
                    v-model.number="props.item.amountToWithdraw"
                    type="number"
                    outlined
                    dense
                    class="pt-5"
                    :suffix="`${props.item.currency} / ${investor.investor.favorite_currency}: ${convertCurrency(
                      currency(props.item.amountToWithdraw),
                      props.item.currency,
                      investor.investor.favorite_currency
                    )}`"
                    :disabled="!props.item.available_funds > 0"
                    :rules="[
                      ...formRules.numberRulesAllowZero,
                      (v) => v <= currency(props.item.available_funds, { fromCents: true }) || 'Fondos insuficientes'
                    ]"
                  />
                </v-form>
              </template>
            </v-data-table>
          </div>
          <v-radio-group v-model="withdrawType" column>
            <v-radio label="Reembolsar por oficina" color="primary" value="byOffices" />
            <v-radio label="Reembolsar todo" color="primary" value="allOffices" />
          </v-radio-group>
        </div>
      </template>
      <template slot="actions">
        <v-btn text @click="(withdrawDialog = false), (selectedCurrencyToWithdraw = '')">Cerrar</v-btn>
        <v-btn text @click="selectedCurrencyToWithdraw !== 'ALLWALLETS' ? withdraw() : withdrawAllWallets()" :disabled="!enableWithdrawBtn">
          Reembolsar
        </v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CommonMixin from "@/mixins/CommonMixin";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import DialogBox from "@/components/elements/DialogBox";
import _ from "lodash";
import * as currency from "currency.js";

export default {
  components: {
    DialogBox
  },
  mixins: [CommonMixin, FormRulesMixin],
  props: {
    investor: {
      type: Object,
      required: true
    },
    wallets: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      withdrawDialog: false,
      withdrawFormValid: false,

      formattedWallets: {},
      selectedCurrencyToWithdraw: "",
      withdrawData: {},
      withdrawType: "byOffices",

      walletsTableHeaders: [
        { text: "Oficina", value: "officeName" },
        { text: "Disponible", value: "available_funds" },
        { text: "Retenciones", value: "withholding_withdraws" },
        { text: "Cantidad a reembolsar", value: "amountToWithdraw" }
      ]
    };
  },
  computed: {
    ...mapState({
      currencies: (state) => state.currencies.currencies,
      offices: (state) => state.offices.offices,
      partners: (state) => state.partners.partners
    }),
    _() {
      return _;
    },
    currency() {
      return currency;
    },

    enableWithdrawBtn() {
      if (this.selectedCurrencyToWithdraw !== "ALLWALLETS") {
        return (this.withdrawType !== "allOffices" && this.withdrawFormValid) || this.withdrawType === "allOffices";
      } else if (this.selectedCurrencyToWithdraw === "ALLWALLETS") return true;
      else return false;
    }
  },
  watch: {
    wallets() {
      this.formatWallets();
    }
  },
  async created() {
    await this.$store.dispatch("partners/getPartners");
  },
  methods: {
    formatWallets() {
      this.formattedWallets = {};

      for (let i = 0; i < this.wallets.length; i++) {
        const wallet = this.wallets[i];
        let walletPartnerId = this.offices.find((e) => e.id === wallet.office_id)?.partner?.id;
        wallet.officeName = this.offices.find((e) => e.id === wallet.office_id)?.name;
        wallet.withholding_withdraws = this.partners.find((e) => e.id === walletPartnerId)?.withholding_withdraws;

        if (!this.formattedWallets[wallet.currency]) {
          this.$set(this.formattedWallets, wallet.currency, {
            mainWallet: wallet.main_wallet,
            totalAvailableFunds: wallet.available_funds,
            flipped: false,
            wallets: [wallet]
          });
        } else {
          this.formattedWallets[wallet.currency].totalAvailableFunds += wallet.available_funds;
          this.formattedWallets[wallet.currency].wallets.push(wallet);
        }
      }
    },

    getWithdrawableCurrencies() {
      const currencies = [];

      for (const currency of this._.keys(this.formattedWallets)) {
        if (!this.formattedWallets[currency].mainWallet && this.formattedWallets[currency].totalAvailableFunds > 0) {
          currencies.push({
            text: this.currencies.find((e) => e.id === currency)?.name,
            value: currency
          });
        }
      }

      if (currencies.length > 0) currencies.unshift({ text: "Todas las carteras", value: "ALLWALLETS" });

      return currencies;
    },

    setWithdrawData(currencyId) {
      if (currencyId !== "ALLWALLETS") {
        this.withdrawData.currency = currencyId;
        const auxWallets = [];
        let maxAmountToWithdraw = 0;

        for (const wallet of this.formattedWallets[currencyId].wallets) {
          auxWallets.push({ ...wallet, amountToWithdraw: 0 });
          maxAmountToWithdraw += wallet.available_funds;
        }

        this.withdrawData.wallets = auxWallets;
        this.withdrawData.maxAmountToWithdraw = maxAmountToWithdraw;
      }
    },

    getAmountWithholding(amount, withholding) {
      const amountWithdrawed = currency(amount).divide(1 + withholding / 100);
      return currency(amount).subtract(amountWithdrawed);
    },

    async withdraw() {
      const withdrawAllCurrency = this.withdrawType === "allOffices";

      if (this.withdrawFormValid || withdrawAllCurrency) {
        const withdraws = [];

        for (let i = 0; i < this.withdrawData.wallets.length; i++) {
          const office = this.withdrawData.wallets[i];
          if (office.amountToWithdraw > 0 || (withdrawAllCurrency && office.available_funds > 0)) {
            withdraws.push({
              amount: withdrawAllCurrency ? office.available_funds : currency(office.amountToWithdraw).intValue,
              office_id: office.office_id
            });
          }
        }

        if (withdraws.length > 0) {
          console.log({ investor_id: this.investor.id, withdraws });
          const response = await this.$store.dispatch("investors/withdraw", { investor_id: this.investor.id, withdraws });
          if (response.data.success) {
            this.$emit("getInvestorWallets");
            this.formatWallets();
            this.withdrawDialog = false;
            this.selectedCurrencyToWithdraw = "";
          }
        }
      }
    },

    async withdrawAllWallets() {
      const withdraws = [];

      for (const currency of this._.keys(this.formattedWallets)) {
        if (!this.formattedWallets[currency].mainWallet) {
          for (let i = 0; i < this.formattedWallets[currency].wallets.length; i++) {
            const office = this.formattedWallets[currency].wallets[i];

            if (office.available_funds > 0) {
              withdraws.push({
                amount: office.available_funds,
                office_id: office.office_id
              });
            }
          }
        }
      }

      if (withdraws.length > 0) {
        console.log({ investor_id: this.investor.id, withdraws });
        const response = await this.$store.dispatch("investors/withdraw", { investor_id: this.investor.id, withdraws });
        if (response.data.success) {
          this.$emit("getInvestorWallets");
          this.formatWallets();
          this.withdrawDialog = false;
          this.selectedCurrencyToWithdraw = "";
        }
      }
    }
  }
};
</script>
