<template>
  <tiptap-vuetify
    v-model="content"
    :extensions="extensions"
    :card-props="{ flat: true }"
    placeholder="Añade comentarios sobre este inversor."
  >
    <div slot="footer" class="text-right pa-3">
      <v-btn icon @click="cancelChanges">
        <v-icon>clear</v-icon>
      </v-btn>
      <v-btn icon @click="saveChanges">
        <v-icon>save</v-icon>
      </v-btn>
    </div>
  </tiptap-vuetify>
</template>

<script>
// import the component and the necessary extensions
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from "tiptap-vuetify";

export default {
  // specify TiptapVuetify component in "components"
  components: { TiptapVuetify },
  props: {
    parentContent: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      // declare extensions you want to use
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3]
            }
          }
        ],
        Bold,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      content: ``
    };
  },
  created() {
    this.content = this.parentContent;
  },
  methods: {
    saveChanges() {
      this.$emit("saveNotesChanges", this.content);
    },
    cancelChanges() {
      this.$emit("cancelNotesChanges", this.content);
    }
  }
};
</script>
