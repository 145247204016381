<template>
  <div>
    <div class="d-inline-flex align-center">
      <div style="cursor: pointer" class="mr-n1" @click="handleSwitchButton()">
        <v-switch v-model="reinvestmentSettings.automatic_investment.active" inset hide-details dense readonly class="ma-0 pa-0" />
      </div>
      <p class="heading4-bold mb-0 mr-2">
        Reinversión automática
        <b>{{ investor.investor.automatic_investment && investor.investor.automatic_investment.active ? "activada" : "desactivada" }}</b>
      </p>
      <v-btn
        v-if="investor.investor.automatic_investment && investor.investor.automatic_investment.active"
        fab
        elevation="1"
        style="width: 27px; height: 27px"
        @click="dialogConfigAutomaticReinvestment = true"
      >
        <v-icon small style="margin-right: -2px">edit</v-icon>
      </v-btn>
    </div>

    <!-- Dialog config automatic reinvestment -->
    <DialogBox :model="dialogConfigAutomaticReinvestment">
      <template slot="toolbar">
        <span class="heading4-bold">
          {{
            investor.investor.automatic_investment && investor.investor.automatic_investment.active ? "Editar reinversión" : "Reinversión"
          }}
          automática
        </span>
      </template>
      <template slot="content">
        <v-form ref="formAutomaticReinvestment">
          <p class="heading5-medium">Configura el tipo de proyecto en el que quieres que apliquemos la reinversión</p>
          <v-autocomplete
            v-model="reinvestmentSettings.automatic_investment.settings.partners"
            :items="partners"
            :label="reinvestmentSettings.automatic_investment.settings.partners.length > 0 ? 'Comunidades' : 'Cualquier comunidad'"
            :menu-props="{ offsetY: true }"
            item-text="name"
            item-value="id"
            multiple
            chips
            class="mt-2"
          />
          <v-text-field
            v-model.number="reinvestmentSettings.automatic_investment.settings.max_amount"
            type="number"
            label="Cantidad máxima"
            class="mt-2"
            :rules="formRules.numberRulesAllowZero"
          />
          <v-select
            v-model="reinvestmentSettings.automatic_investment.settings.num_credits"
            :items="[0, 1, 2, 3, 4, 5]"
            label="Número mínimo de créditos concedidos"
            :menu-props="{ offsetY: true }"
            class="mt-2"
          />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="dialogConfigAutomaticReinvestment = false"> Cerrar </v-btn>
        <v-btn text @click="activeAutomaticInvestment()">
          {{ investor.investor.automatic_investment && investor.investor.automatic_investment.active ? "Guardar" : "Activar" }}
        </v-btn>
      </template>
    </DialogBox>

    <!-- Dialog deactivate automatic reinvestment -->
    <DialogBox :model="dialogDeactivateAutomaticReinvestment">
      <template slot="toolbar">
        <span class="heading4-bold">Desactivar reinversión automática</span>
      </template>
      <template slot="content">
        <p class="heading5-medium">¿Estás seguro que quieres desactivar la reinversión automática?</p>
      </template>
      <template slot="actions">
        <div style="width: 100%" class="d-inline-flex mb-5 justify-space-around">
          <v-btn rounded color="error" class="px-5" @click="deactiveAutomaticInvestment()">Desactivar</v-btn>
          <v-btn color="primary" rounded class="black--text px-5" @click="dialogDeactivateAutomaticReinvestment = false">Mantener</v-btn>
        </div>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DialogBox from "@/components/elements/DialogBox";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import _ from "lodash";

export default {
  components: { DialogBox },
  mixins: [FormRulesMixin],
  props: {
    investor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      reinvestmentSettings: {
        investor_id: "",
        automatic_investment: {
          active: false,
          settings: {
            partners: [],
            max_amount: 0,
            num_credits: 0
          }
        }
      },
      dialogConfigAutomaticReinvestment: false,
      dialogDeactivateAutomaticReinvestment: false
    };
  },
  watch: {
    "investor.investor.automatic_investment.active"() {
      this.reinvestmentSettings.automatic_investment = this._.merge(
        this.reinvestmentSettings.automatic_investment,
        this.investor.investor.automatic_investment
      );
    }
  },
  computed: {
    ...mapState({
      partners: (state) => state.partners.partners
    }),
    _() {
      return _;
    }
  },
  async created() {
    await this.$store.dispatch("partners/getPartners");
    this.reinvestmentSettings.investor_id = this.investor.id;
    this.reinvestmentSettings.automatic_investment = this._.merge(
      this.reinvestmentSettings.automatic_investment,
      this.investor.investor.automatic_investment
    );
  },
  methods: {
    async activeAutomaticInvestment() {
      if (this.$refs.formAutomaticReinvestment.validate()) {
        this.dialogConfigAutomaticReinvestment = false;
        this.reinvestmentSettings.automatic_investment.active = true;

        await this.$store.dispatch("investors/editInvestorAutomaticInvestment", this.reinvestmentSettings);
      }
    },
    async deactiveAutomaticInvestment() {
      this.dialogDeactivateAutomaticReinvestment = false;
      this.reinvestmentSettings.automatic_investment.active = false;

      await this.$store.dispatch("investors/editInvestorAutomaticInvestment", this.reinvestmentSettings);
    },
    handleSwitchButton() {
      if (this.investor.investor.automatic_investment?.active) this.dialogDeactivateAutomaticReinvestment = true;
      else this.dialogConfigAutomaticReinvestment = true;
    }
  }
};
</script>
