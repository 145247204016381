<template>
  <div>
    <v-btn
      small
      @click="
        (dialogDepositFunds = true), (newDeposit.currency = investor.investor.favorite_currency), (newDeposit.investor_id = investor.id)
      "
    >
      Depositar
    </v-btn>

    <!-- Dialog deposit money -->
    <DialogBox :model="dialogDepositFunds">
      <template slot="toolbar">
        <span>Depositar dinero</span>
      </template>
      <template slot="content">
        <v-card-text class="pa-5">
          <v-form ref="formDepositFunds" v-model="formDepositFundsValid">
            <v-text-field
              v-model.number="newDeposit.amount"
              type="number"
              label="Cantidad"
              :suffix="investor.investor.favorite_currency"
              :rules="formRules.numberRules"
            />
          </v-form>
        </v-card-text>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('dialogDepositFunds', 'formDepositFunds')">Cerrar</v-btn>
        <v-btn text @click="depositFundsAction()">Depositar</v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import FormRulesMixin from "@/mixins/FormRulesMixin";
import CommonMixin from "@/mixins/CommonMixin";
import DialogBox from "@/components/elements/DialogBox";

export default {
  components: {
    DialogBox
  },
  mixins: [FormRulesMixin, CommonMixin],
  props: {
    investor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogDepositFunds: false,
      formDepositFundsValid: false,

      newDeposit: {}
    };
  },
  methods: {
    // Deposit money in wallet
    async depositFundsAction() {
      if (this.$refs.formDepositFunds.validate()) {
        const newDepositFormattedData = { ...this.newDeposit };
        newDepositFormattedData.amount = this.convertToInt(newDepositFormattedData.amount);

        await this.$store.dispatch("investors/depositFunds", newDepositFormattedData);
        this.$emit("getInvestorWallets");

        this.closeDialog("dialogDepositFunds", "formDepositFunds");
      }
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog(dialogModel, formRef) {
      this.$refs[formRef].resetValidation();
      this[dialogModel] = false;

      this.newDeposit = this.$options.data().newDeposit;
    }
  }
};
</script>
