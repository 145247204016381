<template>
  <div v-if="!_.isEmpty(investorEditable)">
    <v-text-field v-model="investorEditable.first_name" label="Nombre" :readonly="!editable" :rules="formRules.textRules" />
    <v-text-field v-model="investorEditable.last_name" label="Apellidos" :readonly="!editable" :rules="formRules.textRules" />
    <v-text-field v-model="investorEditable.email" type="email" label="Email" :readonly="!editable" :rules="formRules.emailRules">
      <template v-slot:append>
        <v-icon v-if="investorEditable.completed_steps && investorEditable.completed_steps.includes('EMAIL_VERIFIED')" color="green">
          verified_user
        </v-icon>
        <v-icon v-else color="red">verified_user</v-icon>
      </template>
    </v-text-field>
    <DatePicker
      :parent_model="investorEditable.date_of_birth"
      label="Fecha de nacimiento"
      :type_birthdate="true"
      :required="true"
      :readonly="!editable"
      @change="investorEditable.date_of_birth = $event"
    />
    <v-text-field
      v-model="investorEditable.identity_document"
      label="Documento de indentidad"
      :readonly="!editable"
      :rules="formRules.textRules"
    />
    <DatePicker
      :parent_model="investorEditable.identity_document_expiration_date"
      label="Fecha caducidad del documento de indentidad"
      :required="true"
      :min="dateNextDayFormated()"
      :readonly="!editable"
      @change="investorEditable.identity_document_expiration_date = $event"
    />
    <CountryAutocomplete
      :listener="$store.state.investors.investor"
      :label="'Nacionalidad'"
      :countryId="investorEditable.nationality"
      :editable="editable"
      @selected="investorEditable.nationality = $event"
    />
    <v-text-field v-model="investorEditable.phone" label="Teléfono" :readonly="!editable" :rules="formRules.phoneRules" />
    <v-text-field
      v-model="investorEditable.investor.professional_activity"
      label="Actividad profesional"
      :readonly="!editable"
      :rules="formRules.textRules"
    />
    <CountryAutocomplete
      :listener="$store.state.investors.investor"
      :countryId="investorEditable.country"
      :editable="editable"
      @selected="handleCountrySelection($event)"
    />
    <v-autocomplete
      v-if="!_.isEmpty(investorEditable.country)"
      v-model="investorEditable.city"
      :items="cities"
      item-text="name"
      item-value="id"
      label="Ciudad"
      :readonly="!editable"
      :rules="formRules.textRules"
    />
    <v-text-field
      v-if="!_.isEmpty(investorEditable.city)"
      v-model="investorEditable.zone"
      label="Zona"
      :readonly="!editable"
      :rules="formRules.textRules"
    />
    <v-text-field v-model="investorEditable.address.name" label="Dirección" :readonly="!editable" :rules="formRules.textRules" />
    <div class="d-flex align-center">
      <v-text-field :value="currentCommission" label="Comisión" readonly />
      <v-btn x-small class="ml-2" @click="editInvestorCommissionDialog = true">Editar comisión</v-btn>
    </div>
    <v-select
      v-model="investorEditable.investor.favorite_currency"
      :items="currencies.filter((e) => e.investable === true)"
      item-text="name"
      item-value="id"
      label="Moneda preferida"
      readonly
    />
    <v-select
      v-model="investorEditable.how_know_us"
      :items="howKnowUsOptions.filter((e) => !!e.value.active)"
      item-text="value.name"
      item-value="key"
      chips
      multiple
      label="Cómo nos conoció"
      readonly
    />
    <v-text-field
      v-model="investorEditable.password"
      label="Contraseña (introducir sólo si se desea cambiar)"
      :type="showPassword ? 'text' : 'password'"
      :append-icon="showPassword ? 'visibility' : 'visibility_off'"
      :readonly="!editable"
      :rules="formRules.passwordNotRequiredRules"
      @click:append="showPassword = !showPassword"
    />

    <!-- Edit investor commission dialog -->
    <DialogBox :model="editInvestorCommissionDialog">
      <template slot="toolbar">
        <span>Cambiar comisión</span>
      </template>
      <template slot="content">
        <v-form ref="formEditInvestorCommission" v-model="formEditInvestorCommissionValid">
          <v-select
            v-model="newCommission"
            :items="commissions"
            item-value="key"
            item-text="value.name"
            label="Nueva comisión"
            :rules="[(v) => !_.isEmpty(v) || 'Campo obligatorio']"
          />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('editInvestorCommissionDialog', 'formEditInvestorCommission')">Cerrar</v-btn>
        <v-btn text @click="editCommission()">Aplicar</v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import DatePicker from "@/components/elements/DatePicker";
import CountryAutocomplete from "@/components/elements/locations/CountryAutocomplete";
import DialogBox from "@/components/elements/DialogBox";

export default {
  name: "RetailProfileData",
  components: { DatePicker, CountryAutocomplete, DialogBox },
  mixins: [FormRulesMixin, FormatDateMixin],
  props: {
    investorEditable: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      showPassword: false,

      newCommission: "",
      formEditInvestorCommissionValid: false,
      editInvestorCommissionDialog: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      commissions: (state) => state.investments.commissions,
      currencies: (state) => state.currencies.currencies,
      cities: (state) => state.locations.cities,
      howKnowUsOptions: (state) => state.settings.howKnowUsOptions
    }),
    _() {
      return _;
    },

    currentCommission() {
      return this.commissions.find((e) => e.key === this.investorEditable.investor.commission)?.value.name;
    }
  },
  async created() {
    await this.$store.dispatch("settings/getHowKnowUsOptions");
  },
  methods: {
    async editCommission() {
      if (this.$refs.formEditInvestorCommission.validate()) {
        await this.$store.dispatch("investments/editInvestorCommission", {
          commission: this.newCommission,
          investor_id: this.investorEditable.id
        });

        this.closeDialog("editInvestorCommissionDialog", "formEditInvestorCommission");
      }
    },

    async handleCountrySelection(countryId) {
      await this.$store.dispatch("locations/getCities", { filter: { country: countryId } });
      this.investorEditable.country = countryId;
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog(dialogModel, formRef) {
      this.$refs[formRef].resetValidation();
      this[dialogModel] = false;

      this.newCommission = this.$options.data().newCommission;
    }
  }
};
</script>
